import React from "react";
import EasyBooking from "image/homepage/features/service-flight.png";
import LowestPrice from "image/homepage/features/crypto.png";
import Support from "image/homepage/features/support.png";
import Image from "next/image";
import { Icon } from "@iconify/react";

const features = [
  {
    id: 1,
    title: "Easy Booking",
    description: "Visit some of the world's most rapidly changing places",
    img: EasyBooking,
  },
  {
    id: 2,
    title: "Lowest Price",
    description:
      "We ensure low rates on hotel reservation, holiday packages and on flight tickets.",
    img: LowestPrice,
  },
  {
    id: 3,
    title: "24/7 Support",
    description:
      "Get assistance 24/7 on any kind of travel related query. We are happy to assist you.",
    img: Support,
  },
];

const OtherFeatures = () => {
  return (
    <>
      <section className="pt-0 bg-white dark:bg-dark-bg-primary">
        <div className="container mx-auto">
          {/* <div className="mb-8 text-center">
            <h3 className="popular_header capitalize font-bold text-xl sm:text-3xl text-[#1a1a1a] m-0 ">
              Reasons to choose XcelTrip
            </h3>
            <p className="font-normal ">
              Don't just spend, earn while you travel
            </p>
          </div> */}
          {/* <div className="grid gap-4 md:grid-cols-3">
            {features.map((doc) => (
              <div key={doc.id} className="flex   gap-2 p-3 bg-white rounded-lg shadow-[0px_8px_26px_rgba(0,0,0,0.1)]  h-[fit-content]">
                <Image
                  src={doc.img}
                  alt="feature img"
                  className="w-[80px] rounded h-[80px]"
                />
                <div className="">
                  <p className="mb-1 font-medium text">
                    {doc.title}
                  </p>
                  <p className="m-0 text-sm text-gray-600 description">
                    {doc.description}
                  </p>
                </div>
              </div>
            ))}
          </div> */}

          <div className="grid gap-4 md:grid-cols-3">
            <article className="flex w-full gap-3 p-4 text-black bg-white dark:bg-dark-bg-secondary rounded-md shadow-[0_4px_12px_rgba(0,0,0,0.04)] ">
              <div className="bg-gradient-to-br from-[#9380ff] to-[#b2a4ff]   p-2 rounded-full h-fit w-fit">
                <Icon
                  icon={`solar:ticket-sale-linear`}
                  className="w-6 h-6 text-white"
                />
              </div>
              <div className="h-fit">
                <h5 className="mb-0 text-base font-semibold dark:text-dark-text-primary">
                  Easy Booking
                </h5>
                <p className="mb-0 text-sm text-gray-500 dark:text-dark-text-secondary ">
                  Effortless booking with XcelTrip.
                </p>
              </div>
            </article>
            <article className="bg-white dark:bg-dark-bg-secondary shadow-[0_4px_12px_rgba(0,0,0,0.04)]  text-black rounded-md p-4  w-full flex gap-3 ">
              <div className=" bg-gradient-to-br from-[#56f0ce] to-[#93fae4]   p-2 rounded-full h-fit w-fit">
                <Icon
                  icon={`solar:hand-money-outline`}
                  className="w-6 h-6 text-white"
                />
              </div>
              {/* <img className="max-w-[120px[]" src="" alt="" /> */}
              <div>
                <h5 className="mb-0 text-base font-semibold dark:text-dark-text-primary">
                  Lowest Price
                </h5>
                <p className="mb-0 text-sm text-gray-500 dark:text-dark-text-secondary ">
                  Unbeatable Prices. Save More Today.
                </p>
              </div>
            </article>
            <article className="bg-white dark:bg-dark-bg-secondary shadow-[0_4px_12px_rgba(0,0,0,0.04)]   text-black rounded-md p-4  w-full flex gap-3 ">
              <div className=" bg-gradient-to-br from-[#fd7ca0] to-[#eeafc1] p-2 rounded-full h-fit w-fit">
                <Icon
                  icon={`solar:headphones-square-sound-linear`}
                  className="w-6 h-6 text-white"
                />
              </div>
              {/* <img className="max-w-[120px[]" src="" alt="" /> */}
              <div>
                <h5 className="mb-0 text-base font-semibold dark:text-dark-text-primary">
                  24/7 Support
                </h5>
                <p className="mb-0 text-sm text-gray-500 dark:text-dark-text-secondary">
                  Uninterrupted Support, Anytime, Anywhere.
                </p>
              </div>
            </article>
          </div>
        </div>
      </section>
    </>
  );
};

export default OtherFeatures;
