import { Icon } from "@iconify/react";
import Image from "next/image";
import React, { useState } from "react";

const Promo = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [startX, setStartX] = useState(0);

  const images = [
    "/image/promo/promo-1.webp",
    "/image/promo/promo-2.webp",
    "/image/promo/promo-3.webp",
  ];

  const handleNext = () => {
    if (currentIndex < images.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleTouchStart = (event) => {
    setStartX(event.touches[0].clientX);
  };

  const handleTouchEnd = (event) => {
    const endX = event.changedTouches[0].clientX;
    const deltaX = startX - endX;

    if (deltaX > 50) {
      handleNext();
    } else if (deltaX < -50) {
      handlePrev();
    }
  };
  return (
    <React.Fragment>
      <section className="bg-white dark:bg-dark-bg-secondary">
        <div className="container mx-auto">
          <h3 class="popular_header capitalize font-bold text-xl sm:text-3xl text-[#1a1a1a] dark:text-dark-text-primary m-0 ">
            Offers
          </h3>
          <p class="font-normal dark:text-dark-text-secondary ">
            Enjoy exciting deals and offers
          </p>
          <div className="items-center hidden gap-3 md:flex ">
            <div className="overflow-hidden w-fit">
              <Image
                fill
                objectFit="cover"
                src={images[0]}
                alt=""
                className="rounded-lg w-full !static h-full top-0 left-0    "
              />
            </div>
            <div className="overflow-hidden w-fit">
              <Image
                fill
                objectFit="cover"
                src={images[1]}
                alt=""
                className="rounded-lg w-full !static h-full top-0 left-0     "
              />
            </div>
            <div className="overflow-hidden w-fit">
              <Image
                fill
                objectFit="cover"
                src={images[2]}
                alt=""
                className="rounded-lg w-full !static h-full top-0 left-0     "
              />
            </div>
          </div>

          <div className="relative block image-carousel md:hidden">
            <div
              onTouchStart={handleTouchStart}
              onTouchEnd={handleTouchEnd}
              style={{
                overflow: "hidden",
              }}
            >
              <button
                type="button"
                title="prev"
                style={{ zIndex: "1" }}
                className="absolute -left-2 top-1/2 bg-black/30 backdrop-blur-md"
                disabled={currentIndex === 0}
                onClick={handlePrev}
              >
                <Icon
                  className="text-2xl text-white"
                  icon={`ic:round-chevron-left`}
                />
              </button>
              <div
                className="image-carousel-inner"
                style={{
                  display: "flex",
                  transition: "transform 0.3s ease-in-out",
                  transform: `translateX(-${currentIndex * 100}%)`,
                }}
              >
                {images.map((image, index) => (
                  <Image
                    fill
                    key={index}
                    src={image}
                    alt={`Image ${index + 1}`}
                    className="rounded-lg !w-full !h-full !static"
                    style={{ width: "100%" }}
                  />
                ))}
              </div>
              <button
                type="button"
                title="next"
                className="absolute -right-2 top-1/2 bg-black/30 backdrop-blur-md"
                onClick={handleNext}
                disabled={currentIndex === images.length - 1}
              >
                <Icon
                  className="text-2xl text-white"
                  icon={`ic:round-chevron-right`}
                />
              </button>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Promo;
