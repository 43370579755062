import { createSlice } from "utils/@reduxjs/toolkit";
import { ContainerState } from "./types";
import { ddlMapper } from "utils/helperFunctions";
import { toast } from "react-toastify";

export const initialState: ContainerState = {
  requesting: false,
  success: false,
  error: null,
  successMessage: "",
  hotelListData: [],
  accessToken: "",
  availableFilter: [],
  recentFlightSearch: [],
  recentHotelSearch: [],
};

const loginSlice = createSlice({
  name: "homepage",
  initialState,
  reducers: {
    getHotelListReq(state: any) {
      state.requesting = true;
      state.error = null;
      state.successMessage = "";
      state.success = false;
      state.hotelListData = [];
      state.availableFilter = [];
    },
    getHotelListSucc(state: any, action: any) {
      let mergedDropdown: any = [];
      let pushAvailabelCategory: any = [];
      if (action.payload.data.cities) {
        const hotelsList =
          ddlMapper(
            action.payload.data.hotels,
            ["dataCat", "string", "string"],
            "Hotels",
            true
          ) || [];
        const citiesList =
          ddlMapper(
            action.payload.data.cities,
            ["dataCat", "string", "string"],
            "Cities",
            true
          ) || [];
        const locationList =
          ddlMapper(
            action.payload.data.locations,
            ["dataCat", "string", "string"],
            "Locations",
            true
          ) || [];
        mergedDropdown = [...citiesList, ...hotelsList, ...locationList];
        hotelsList.length && pushAvailabelCategory.push({ label: "Hotels" });
        citiesList.length && pushAvailabelCategory.push({ label: "Cities" });
        locationList.length &&
          pushAvailabelCategory.push({ label: "Locations" });
        pushAvailabelCategory.length > 1 &&
          pushAvailabelCategory.unshift({ label: "All" });
      } else {
        mergedDropdown = action.payload.data.map(
          (eachAirport: any, eachIndex: any) => ({
            ...eachAirport,
            dataCat: "Airport",
            text: `${eachAirport.city}, ${eachAirport.country_code} (${eachAirport.ita_code})`,
            value: eachAirport.ita_code,
            key: eachAirport.id,
          })
        );
      }
      state.availableFilter = pushAvailabelCategory;
      const defaultDropdown = [{ value: "Result not found", id: "" }];
      state.hotelListData =
        mergedDropdown.length === 0 ? defaultDropdown : mergedDropdown;
      state.requesting = false;
      state.error = null;
      state.success = true;
    },
    getHotelListFail(state: any, action: any) {
      const responseData = action.payload.message;
      state.successMessage = "";
      state.error = responseData;
      state.requesting = false;
      state.success = false;
      toast.error(action.payload.message.name || action.payload.message, {
        toastId: "error",
      });
    },
    getHotelRecentSearchReq(state: any) {
      state.recentHotelSearch = [];
    },
    getHotelRecentSearchSucc(state: any, action: any) {
      state.recentHotelSearch = action.payload.recentSearch || [];
    },
    getHotelRecentSearchFail(state: any, action: any) {
      state.recentHotelSearch = [];
    },
    getFlightRecentSearchReq(state: any) {
      state.recentFlightSearch = [];
    },
    getFlightRecentSearchSucc(state: any, action: any) {
      state.recentFlightSearch = action.payload.recentSearch || [];
    },
    getFlightRecentSearchFail(state: any, action: any) {
      state.recentFlightSearch = [];
    },
    clearHotelState(state: any) {
      state.requesting = false;
      state.error = null;
      state.hotelListData = [];
      state.success = false;
      state.successMessage = "";
      state.availableFilter = [];
      // state.recentHotelSearch = [];
      // state.recentFlightSearch = [];
    },
  },
});

export const { actions: homepageActions, reducer, name: sliceKey } = loginSlice;
