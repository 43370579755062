import React, { useContext, useRef } from "react";
import { homepageSaga } from "./saga";
import { useSelector, useDispatch } from "react-redux";
import { sliceKey, reducer, homepageActions } from "./slice";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import Context from "contexts/context";
import { initializeActions } from "src/Initialize/slice";
import {
  selectEventList,
  selectHotelRecentSearch,
  selectFlightRecentSearch,
} from "src/Initialize/selectors";
import AdsImage from "image/homepage/ads.webp";

/* import PopularDestination from "./components/popularDestination";
import EventList from "./components/eventList";
import HeroPage from "./components/heroPage";
import CryptoPage from "./components/cryptoPage";
import OfferPage from "./components/offerPage";
import NewsLetter from "./components/newsLetter"; */

import dynamic from "next/dynamic";
import OtherFeatures from "./components/otherFeatures";
import Image from "next/image";
import { Tabs } from "flowbite-react";
import Promo from "./components/promo";
// import MobileLink from "./components/mobiledownload";
const MobileLink = dynamic(() => import("./components/mobiledownload"));
const PopularDestination = dynamic(
  () => import("./components/popularDestination")
);
const RecentSearches = dynamic(() => import("./components/recentSearches"), {
  ssr: false,
});
const EventList = dynamic(() => import("./components/eventList"), {
  ssr: false,
});
const HeroPage = dynamic(() => import("./components/heroPage"));
const CryptoPage = dynamic(() => import("./components/cryptoPage"));
const OfferPage = dynamic(() => import("./components/offerPage"), {
  ssr: false,
});
const NewsLetter = dynamic(() => import("./components/newsLetter"), {
  ssr: false,
});
const Footer = dynamic(() => import("comp/footer"), {
  ssr: false,
});

interface HomePage {
  defaultSelectedType: String;
}

const HomePage = ({ defaultSelectedType }: HomePage) => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: homepageSaga });
  const heroRef: any = useRef(null);
  const executeScroll = () => heroRef.current.scrollIntoView();
  const {
    pageTemplate,
    userDetails,
  }: {
    pageTemplate: any;
    userDetails: any;
  } = useContext(Context);
  const eventList = useSelector(selectEventList);
  const recentHotelSearch = useSelector(selectHotelRecentSearch);
  const recentFlightSearch = useSelector(selectFlightRecentSearch);
  const dispatch = useDispatch();

  const [currentTab, setCurrentTab] = React.useState(defaultSelectedType);
  React.useEffect(() => {
    // window.scroll(0, 0);
    dispatch(initializeActions.getEventListReq());
  }, []);

  React.useEffect(() => {
    if (userDetails._id) {
      dispatch(initializeActions.getHotelRecentSearchReq());
      // dispatch(initializeActions.getFlightRecentSearchReq());
    }
  }, [userDetails]);

  return (
    <React.Fragment>
      <div ref={heroRef}>
        <HeroPage
          currentTab={currentTab}
          defaultSelected={defaultSelectedType}
          setCurrentTab={setCurrentTab}
        />
      </div>
      <CryptoPage />
      <OtherFeatures />
      <Promo />
      <PopularDestination />

      <RecentSearches
        currentTab={currentTab}
        hotelData={recentHotelSearch}
        flightData={recentFlightSearch}
      />
      <NewsLetter />
      {(eventList?.length && (
        <EventList data={eventList} handleScroll={executeScroll} />
      )) ||
        null}
      {/* <MobileLink /> */}

      <Footer />
    </React.Fragment>
  );
};

export default HomePage;
