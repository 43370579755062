import { tokenId } from "./../../utils/authorizationHelper";
import {
  take,
  takeLatest,
  fork,
  put,
  select,
  delay,
  cancelled,
} from "redux-saga/effects";
import API from "utils/apiHelper";
import { homepageActions as actions } from "./slice";
import Router from "next/router";
import { search } from "constants/apiConfig";
/**
 * Github repos request/response handler
 */

export function* getHotelListRequest(action: any) {
  yield delay(500)
  const { payload } = action;

  console.log("🚀 ~ function*getHotelListRequest ~ payload:", payload)

  const api =
    payload.type === "flight"
      ? search.flightSearchApi + payload.value
      : search.hotelSearchApi + payload;
  try {
    yield fork(
      API.get(
        api,
        actions.getHotelListSucc,
        actions.getHotelListFail,
        "",
        "GET"
      )
    );
  } catch (err) {
    yield put(actions.getHotelListFail(err));
  }
}
export function* getRecentHotelSearch(action: any) {
  try {
    yield fork(
      API.get(
        search.getRecentHotelSearch + tokenId()._id,
        actions.getHotelRecentSearchSucc,
        actions.getHotelRecentSearchFail,
        "",
        "GET"
      )
    );
  } catch (err) {
    yield put(actions.getHotelListFail(err));
  }
}

export function* getFlightRecentSearch(action: any) {
  try {
    yield fork(
      API.get(
        search.getFlightRecentSearch + tokenId()._id,
        actions.getFlightRecentSearchSucc,
        actions.getFlightRecentSearchFail,
        "",
        "GET"
      )
    );
  } catch (err) {
    yield put(actions.getHotelListFail(err));
  }
}

export function* homepageSaga() {
  yield takeLatest(actions.getHotelListReq.type, getHotelListRequest);
  yield takeLatest(actions.getHotelRecentSearchReq.type, getRecentHotelSearch);
  yield takeLatest(
    actions.getFlightRecentSearchReq.type,
    getFlightRecentSearch
  );
}
